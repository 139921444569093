import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';


const About = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('About Us')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>About Us</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">  
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-6">Learn More About Us</h2>
            <p>Welcome to {Application.ORG_DISPLAY_NAME} - your gateway to unforgettable travel experiences around the world. We are a passionate team of travel enthusiasts dedicated to curating and delivering exceptional travel adventures that will leave you with cherished memories for a lifetime. At {Application.ORG_DISPLAY_NAME}.com, we believe in the transformative power of travel, and our mission is to help you explore and discover the beauty, diversity, and cultural richness of our planet.</p>
<h3>Our Vision</h3>
<p>"Travel makes one modest, you see what a tiny place you occupy in the world." - Gustave Flaubert</p>
<p>At {Application.ORG_DISPLAY_NAME}, our vision is to inspire and empower travelers to step out of their comfort zones and embark on extraordinary journeys. We aim to be more than just a travel website; we want to be your trusted companion, encouraging you to embrace different cultures, connect with people from around the globe, and foster a sense of empathy and understanding. We believe that travel not only broadens your horizons but also enriches your soul.</p>
<h3>Our Mission</h3>
<p>"The world is a book, and those who do not travel read only one page." - Saint Augustine</p>
<p>Our mission is to curate and provide a wide array of travel options that cater to diverse interests and preferences. Whether you're an adventurous explorer seeking thrilling escapades, a cultural enthusiast fascinated by history and traditions, or a leisure seeker looking for relaxation in idyllic settings, {Application.ORG_DISPLAY_NAME}.com has something special for you. We are committed to ensuring your travel dreams turn into reality by offering seamless planning, exceptional service, and unforgettable experiences.</p>
<h3>Our Services</h3>
<p>"Traveling - it leaves you speechless, then turns you into a storyteller." - Ibn Battuta</p>
<p>At {Application.ORG_DISPLAY_NAME}, we take pride in delivering comprehensive travel services that cater to every aspect of your journey. From carefully crafted itineraries to personalized trip planning, we strive to make your travel experience hassle-free and unforgettable. Our services include:</p>
<h3>1. Tailored Itineraries</h3>
<p>Our team of experienced travel experts work closely with you to create customized itineraries that align with your interests, schedule, and budget. Whether you're planning a solo adventure, a family vacation, or a romantic getaway, we ensure that every detail is taken care of, so you can focus on making memories.</p>
<h3>2. Accommodation</h3>
<p>We understand the importance of comfortable and cozy accommodations during your travels. With our extensive network of partner hotels, resorts, and unique lodgings, we offer you a range of options to suit your preferences, ensuring you have a home away from home.</p>
<h3>3. Guided Tours</h3>
<p>To truly immerse yourself in the local culture and history, we provide expert-guided tours that take you to hidden gems and must-see landmarks. Our knowledgeable guides add depth to your experiences, offering insights and stories that will leave you captivated.</p>
<h3>4. Adventure Activities</h3>
<p>For the adrenaline junkies seeking thrilling adventures, we offer a plethora of adrenaline-pumping activities like hiking, diving, wildlife safaris, and more. Unleash your adventurous spirit and create unforgettable moments.</p>
<h3>5. Travel Resources</h3>
<p>To empower and enrich your travel experiences, we offer a range of resources, including travel tips, destination guides, and cultural information. We believe that a well-informed traveler is a confident traveler.</p>
<p></p>
            <div className="row mt-4 mb-2">
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-thumbs-up"></i>
                  </div>
                  <h3>Best Deal Offer</h3>
                  <p>
                    Find our lowest deal to destinations worldwide, guaranteed
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-paper-plane"></i>{' '}
                  </div>
                  <h3>Easy Booking</h3>
                  <p>
                    Search, select and save - the fastest way to book your trip
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="featured-box style-1">
                  <div className="featured-box-icon text-primary">
                    {' '}
                    <i className="far fa-eye"></i>{' '}
                  </div>
                  <h3>24/7 Customer Care</h3>
                  <p>
                    Get award-winning service and special deals by calling{' '}
                    {Application.SUPPORT_PHONE}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
