import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import {PageUrl} from './Constants';
import Application from './config/application';

const CancelRefund = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Cancellation & Refund Policy')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Cancellation & Refund Policy</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Cancellation & Refund Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <p>At {Application.ORG_DISPLAY_NAME}, we understand that travel plans can change unexpectedly. This Cancellation &amp; Refund Policy outlines the terms and conditions governing the cancellation of bookings made through {Application.ORG_DISPLAY_NAME} and the applicable refund procedures. By availing our travel services, you agree to comply with this policy.</p>
            <h2 className="text-6"> Booking Cancellation </h2>
<h4>1. Cancellation Requests</h4>
<p>If you need to cancel your travel booking, please notify us as soon as possible. You can initiate a cancellation request by contacting our customer support team via phone or email. Please provide your booking details and reasons for cancellation.</p>
<h4>2. Cancellation Fees</h4>
<p>Cancellation fees may apply depending on the travel service providers' policies, such as airlines, hotels, tour operators, etc. These fees are subject to their terms and conditions, and {Application.ORG_DISPLAY_NAME} will not impose additional cancellation charges beyond what the service providers require.</p>
<h4>3. Non-Refundable Bookings</h4>
<p>Certain travel bookings may be non-refundable or have specific cancellation restrictions imposed by the service providers. In such cases, the entire booking amount may be forfeited if canceled.</p>
<h2 className="text-6"> Refund Process </h2> 
<h4>1. Refund Eligibility</h4>
<p>To be eligible for a refund, the cancellation request must be made within the service providers' specified timeframes and in accordance with their cancellation policies. Additionally, the booking must be refundable as per the terms and conditions set by the service providers.</p>
<h4>2. Refund Timeframe</h4>
<p>Refunds will be processed within a reasonable timeframe, typically within 7 to 14 business days from the date of receiving the eligible cancellation request. However, the actual time it takes for the refund to be reflected in your account may vary depending on your payment method and financial institution.</p>
<h4>3. Refund Method</h4>
<p>Refunds will be issued using the same payment method used for the original booking, unless otherwise specified by the service providers. If a refund to the original payment method is not possible, an alternative refund method will be discussed with you.</p>
<h3>Force Majeure Events</h3>
<p>In the event of force majeure circumstances, such as natural disasters, political unrest, or travel advisories, that result in the suspension or cancellation of travel services, {Application.ORG_DISPLAY_NAME} and the service providers may offer alternative travel arrangements or issue vouchers for future use. Refunds in such cases will be subject to the specific policies and regulations of the service providers and may be subject to deductions for any unrecoverable costs incurred.</p>
<h3>Travel Insurance</h3>
<p>{Application.ORG_DISPLAY_NAME} strongly recommends purchasing comprehensive travel insurance that covers trip cancellations and disruptions. Travel insurance may provide additional protection and financial assistance in case of unexpected cancellations and travel emergencies. Please review your insurance policy for details regarding coverage and claims procedures.</p>
<h3>Amendments to Bookings</h3>
<p>If you wish to modify your travel bookings, such as changing travel dates or destinations, please contact our customer support team. Amendments are subject to availability and the policies of the service providers. Additional charges may apply based on the changes made.</p>
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancelRefund;
