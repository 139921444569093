import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Terms = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Terms and Conditions')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Terms and Conditions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
             <div> 
              <p>Welcome to {Application.ORG_DISPLAY_NAME} . These Terms and Conditions govern your use of our website and the services offered by {Application.ORG_DISPLAY_NAME} . By accessing our website and availing our travel services, you agree to comply with these Terms and Conditions. Please read this document carefully before proceeding with your bookings or interactions with {Application.ORG_DISPLAY_NAME} .</p>
<h3>Booking and Travel Policies</h3>
<h4>1. Eligibility</h4>
<p>To use our services and make bookings through {Application.ORG_DISPLAY_NAME} , you must be at least 18 years old. If you are under 18, you may only use our website and services with the involvement and consent of a parent or guardian.</p>
<h4>2. Booking Process</h4> 
<p>When booking travel services through {Application.ORG_DISPLAY_NAME} , please ensure that all information provided is accurate and complete. Any discrepancies in the information provided may lead to booking errors or additional charges. It is your responsibility to review and verify the booking details before confirming your reservation.</p>
<h4>3. Payments and Fees</h4>
<p>All payments made through {Application.ORG_DISPLAY_NAME}  must be in the specified currency and paid using accepted payment methods. You are responsible for any additional fees, including currency conversion fees, charged by your bank or financial institution. Any applicable taxes and surcharges will be included in the final booking price.</p>
<h4>4. Booking Confirmation</h4>
<p>Once you complete the booking process and make the necessary payment, you will receive a confirmation email from {Application.ORG_DISPLAY_NAME}  containing your booking details. Please ensure you retain this confirmation for reference during your travel.</p>
<h4>5. Changes and Cancellations</h4>
<p>Changes or cancellations to your travel arrangements may be subject to fees and penalties imposed by airlines, hotels, tour operators, or other service providers. {Application.ORG_DISPLAY_NAME}  will make reasonable efforts to assist you with any changes or cancellations; however, we cannot guarantee the availability of such modifications.</p>
<h3>Travel Insurance</h3>
<p>{Application.ORG_DISPLAY_NAME}  strongly recommends that you obtain comprehensive travel insurance before embarking on your journey. Travel insurance provides protection against unforeseen events such as trip cancellations, medical emergencies, and lost luggage. By purchasing travel insurance, you can enjoy peace of mind during your travels.</p>
<h3>Intellectual Property</h3>
<p>The content, logos, graphics, and trademarks displayed on {Application.ORG_DISPLAY_NAME}  are the intellectual property of {Application.ORG_DISPLAY_NAME}  and its affiliates. You are prohibited from using, reproducing, or distributing any of these materials without the explicit written consent of {Application.ORG_DISPLAY_NAME} .</p>
<h3>User Conduct</h3>
<p>When using {Application.ORG_DISPLAY_NAME} , you agree to abide by the following rules of conduct:</p>
<ul>
<li>You will not engage in any unlawful, fraudulent, or malicious activities on our website.</li>
<li>You will not attempt to interfere with the proper functioning of {Application.ORG_DISPLAY_NAME} , including but not limited to transmitting viruses or harmful code.</li>
<li>You will not use automated means, including bots, to access or interact with our website.</li>
<li>You will not impersonate any individual or entity when using our services.</li>
</ul>
<h3>Limitation of Liability</h3>
<p>{Application.ORG_DISPLAY_NAME}  strives to provide accurate and up-to-date information; however, we do not warrant the accuracy, completeness, or reliability of the content on our website. We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of {Application.ORG_DISPLAY_NAME}  or any services provided by third-party vendors.</p>
<h3>Indemnification</h3>
<p>You agree to indemnify and hold harmless {Application.ORG_DISPLAY_NAME}  and its officers, directors, employees, and affiliates from any claims, damages, liabilities, or expenses arising from your use of our website or services or any violation of these Terms and Conditions.</p>
<h3>Termination</h3>
<p>{Application.ORG_DISPLAY_NAME}  reserves the right to terminate your access to our website and services at any time without prior notice if you violate these Terms and Conditions or engage in any activities that may harm our website or reputation.</p>
<h3>Changes to the Terms and Conditions</h3>
<p>{Application.ORG_DISPLAY_NAME}  may update these Terms and Conditions from time to time without prior notice. Any modifications will be effective immediately upon posting on {Application.ORG_DISPLAY_NAME} . Your continued use of our website and services after the changes take effect constitutes your acceptance of the revised Terms and Conditions.</p>

              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
