import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link as RouterLink} from 'react-router-dom';
import {composePageTitle} from './Common';
import Footer from './components/Footer';
import Header from './components/Header';
import Application from './config/application';
import {PageUrl} from './Constants';

const Privacy = () => {
  return (
    <div id="main-wrapper">
      <Helmet title={composePageTitle('Privacy Policy')} />
      <Header />
      <section className="page-header page-header-dark bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>Privacy Policy</h1>
            </div>
            <div className="col-md-4">
              <ul className="breadcrumb justify-content-start justify-content-md-end mb-0">
                <li>
                  <RouterLink to={PageUrl.HOME}>Home</RouterLink>
                </li>
                <li className="active">Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div id="content">
        <div className="container">
          <div className="bg-white shadow-md rounded p-4">
            <div>
               <p>At {Application.ORG_DISPLAY_NAME}, we understand the significance of safeguarding your personal information and are committed to maintaining your privacy and security. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you interact with our website and use our travel services. By accessing {Application.ORG_DISPLAY_NAME} and availing our services, you consent to the practices described in this policy.</p>
<h3>Information We Collect </h3>
<h4>1. Personal Information </h4>
<p>When you use our website or book travel services through {Application.ORG_DISPLAY_NAME}, we may collect certain personal information such as your name, contact details, email address, date of birth, and payment information. This information is essential for us to process your bookings, communicate with you effectively, and ensure a seamless travel experience.</p>
<h4>2. Usage Data </h4>
<p>We may automatically collect information about your interactions with our website, including your IP address, browser type, device type, pages visited, and the time and date of your visits. This data helps us improve our website's functionality, tailor our services to better meet your needs, and analyze trends.</p>
<h4>3. Cookies and Similar Technologies </h4>
<p>To enhance your browsing experience, we use cookies and similar technologies that store small pieces of data on your device. These cookies help us remember your preferences, track your activities on our website, and provide relevant content and advertisements. You can manage your cookie preferences through your browser settings.</p>
<h3>How We Use Your Information </h3>
<h4>1. Providing Travel Services </h4>
<p>We use the personal information you provide to process your bookings, reservations, and payments, ensuring your travel arrangements are seamless and hassle-free. We may also use your contact details to send you important travel-related updates and confirmations.</p>
<h4>2. Improving User Experience </h4>
<p>The data we collect, including usage information and cookies, allows us to analyze user behavior and preferences. This analysis helps us optimize our website's design, content, and functionality, ensuring a more personalized and user-friendly experience for our visitors.</p>
<h4>3. Marketing and Communication </h4>
<p>With your consent, we may use your contact information to send you promotional offers, newsletters, and updates about new travel destinations, exclusive deals, and exciting travel packages. You can opt-out of marketing communications at any time.</p>
<h4>4. Legal and Security Purposes </h4>
<p>In certain cases, we may use your information to comply with legal obligations, respond to legal requests, protect our rights, privacy, safety, and property, and prevent fraud or any illegal activities.</p>
<h4>Data Sharing and Disclosure </h4>
<p>We understand the importance of confidentiality, and we do not sell, trade, or rent your personal information to third parties for marketing purposes. However, we may share your data with trusted service providers and partners who assist us in delivering our travel services, such as hotels, airlines, tour operators, and payment processors. These entities are bound by confidentiality agreements and are only authorized to use your data to fulfill their specific roles in your travel arrangements.</p>
<p>Additionally, we may share aggregated, non-personal information for statistical analysis and business planning purposes.</p>
<h4>Data Security </h4> 
<p>At {Application.ORG_DISPLAY_NAME}, we employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use secure socket layer (SSL) technology to encrypt data transmission and secure servers to store your information.</p>
<h3>Your Rights and Choices </h3> 
<h4>1. Access and Correction </h4>
<p>You have the right to access the personal information we hold about you and make corrections if necessary. You can do this by logging into your {Application.ORG_DISPLAY_NAME} account or contacting our customer support team.</p>
<h4>2. Opt-out </h4>
<p>If you wish to opt-out of receiving marketing communications from {Application.ORG_DISPLAY_NAME}, you can unsubscribe by following the instructions provided in the emails or contacting us directly.</p>
<h4>3. Cookie Preferences </h4>
<p>Most web browsers allow you to manage your cookie preferences. You can choose to accept or decline cookies. However, disabling cookies may impact your browsing experience on our website.</p>
<h4>Children's Privacy </h4>
<p>We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe your child has provided us with their data without your consent, please contact us, and we will take appropriate steps to remove the information from our records.</p>
<h4>Changes to the Privacy Policy </h4>
<p>We may update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. Any modifications will be effective immediately upon posting on {Application.ORG_DISPLAY_NAME}, and we encourage you to review the policy periodically.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
